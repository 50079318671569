.heading {
  text-align: center;
  font-weight: bold;
  font-size: var(--font-size-2xl);
  color: var(--color-black);
  line-height: var(--leading-tight);
}

.landlordsAnchor {
  text-align: center;
}

.description {
  padding-top: var(--space-3x);
  text-align: center;
  font-size: var(--font-size-body);
}

.content {
  padding: var(--space-6x) 0 0;
}

.createAccount {
  text-align: center;
  font-size: var(--font-size-body);
  margin-top: var(--space-2x);
}

.footer {
  border-top: 1px solid var(--color-gray-200);
  padding-top: var(--space-6x);
  margin-top: var(--space-6x);
}

.clientLoginOptions {
  display: flex;
  margin-top: var(--space-6x);
  gap: var(--space-3x);
}

.clientOption {
  display: flex;
  flex-direction: column;
  background-color: var(--color-indigo-100);
  padding: var(--space-2-5x);
  font-size: var(--font-size-body);
  width: 50%;
  line-height: var(--leading-normal);
  border-radius: var(--border-radius-2x);
  text-align: center;
  justify-content: center;
}
