.subtitle {
  font-size: var(--font-size-md);
  margin-bottom: var(--space-6x);
  line-height: var(--leading-relaxed);
}

.list {
  list-style: none;
  padding: 0;
  display: grid;
  font-size: var(--font-size-body);

  @media --screen-md {
    max-width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.listItem {
  padding-bottom: var(--space-3x);
}

.hidden {
  display: none;
}

.visible {
  display: list-item;
}

.viewMoreOrLessButton {
  font-size: var(--font-size-body);
  padding-top: var(--space-3x);
  padding-bottom: var(--space-6x);
}

.withoutButton {
  margin-bottom: var(--space-8x);
}
